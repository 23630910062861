import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";

function App() {
  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsReady(true);
    }, 13000); // 13 секунд

    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="App">
      <header className="App-header">
        {!isReady ? (
          <>
            <h2>We are what we want,</h2>
            <br />
            <h3>we are what I want,</h3>
            <br />
            <h4>we are what everyone wants</h4>
          </>
        ) : (
          <div className='time'>
            <h>
            Sorry, but we are not ready to accept users yet, the project is being developed by one developer and there are no plans to commercialize this system yet
            </h>
            <a className='th1rd'>th1rd</a>
          </div>
        )}
      </header>
    </div>
  );
}

export default App;
